import React, { useCallback, useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import floatFormat from "../../utils/formats/float";
import * as style from "./RewardPointsCheckout.module.scss";

function RewardPointsCheckout({ handleChange, total, subTotal = 0, promoCode }) {
  const [input, setInput] = useState(null);
  const [useParameter, setUseParameter] = useState(false);
  const [resultPoints, setResultPoints] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    handleChange(useParameter ? resultPoints : null);
  }, [resultPoints, useParameter]);

  const location = useLocation();

  useEffect(() => {
    if (useParameter && promoCode && subTotal + resultPoints === resultPoints) {
      const totalCart = (Array.isArray(location.state) ? location.state : [location.state]).reduce(
        (acc, el) => (acc += el.quantity * el.price),
        0
      );
      const newValue = Math.min(Math.max(totalCart - promoCode, 0), resultPoints);
      setResultPoints(newValue);
      setInput(newValue);
    }
  }, [subTotal, promoCode, useParameter]);

  const onChangeInput = useCallback(
    ({ target: { value } }) => {
      const amount = +value.replace("-", "");
      console.log(amount,total,subTotal)
      setInput(amount <= total && amount <= subTotal + resultPoints ? amount || "" : input);
    },
    [error, input, total, subTotal]
  );

  const onChangeCheckbox = useCallback(({ target: { checked } }) => setUseParameter(checked), []);

  const setRewardAmount = useCallback(() => {
    setResultPoints(input);
  }, [input]);
  

  return (
    <Form.Group>
      <Form.Check>
        <Form.Check.Input
          bsPrefix="bns-checkbox"
          type="checkbox"
          id="pr1"
          checked={useParameter}
          onChange={onChangeCheckbox}
        />
        <Form.Check.Label id="chP" htmlFor="pr1" />
        <Form.Check.Label id="chp1" htmlFor="pr1">I want to use my reward amount</Form.Check.Label>
      </Form.Check>
      <div className={style.container}>
        <div className={style.cnt}>
          <input
            disabled={!useParameter}
            type="number"
            min="0"
            max={total}
            step="any"
            placeholder="Amount covered by reward amount"
            className={style.input}
            value={input}
            onChange={onChangeInput}
          />
          <span className={!total ? style.errSpan : style.span}>
            {!total
              ? "You cant use reward amount bonus your total is 0.00"
              : `*Total balance is $${floatFormat(total)}`}
          </span>
          {error && (
            <Alert variant="danger">{error.response?.data?.message || error.message}</Alert>
          )}
        </div>
        <button onClick={setRewardAmount} disabled={!useParameter} type="button">
          Apply
        </button>
      </div>
    </Form.Group>
  );
}

export default RewardPointsCheckout;
