/* eslint-disable react-hooks/exhaustive-deps */
import { faSync, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as style from "./Dob.module.scss";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { complianceUpdate } from "utils/services/api";
import routers from "./../../router";
import moment from 'moment';
import { DatePicker } from 'antd';
import { useLocation } from "react-router-dom";


function Dob({history,loading}) {

  const location = useLocation();
  const user = location.state.user;

  const [isProgress, setIsProgress] = useState(false);
  const [userIsAbove21,setUserIsAbove21] = useState(true)

  const [fields, setFields] = useState({
    email:user.email,
    id:user.id,
    dateOfBirth: null,
    consentForTextMessages: false,
    phone: "",
    acceptTermsConditions: false,
  });

  useEffect(() => {
    setIsProgress(loading);
  }, [loading]);

  const checkForm = useMemo(() => {
    const { acceptTermsConditions, consentForTextMessages, phone } = fields;
    return !(
      acceptTermsConditions &&
      consentForTextMessages &&
      userIsAbove21 &&
      phone &&
      phone.length==10
    );
  }, [fields.acceptTermsConditions, fields.consentForTextMessages, userIsAbove21, fields.phone]);

  const handleDateChange = (date) => {
    console.log(date.toDate());
    setFields((prevFields) => ({
      ...prevFields,
      dateOfBirth: date ? date.toDate() : null,
    }));    
    setUserIsAbove21(isUserAbove21(date.toDate()))
  };

  const handlerFields = ({ target }) => {
    const { dataset, value } = target;
    const { field } = dataset;
    if (field !== "phone") {
      setFields({
        ...fields,
        [field]: value,
      });
    } else {
      const phone = value.replace(/\D/g, "").trim();
      if (phone.length > 0 && phone.length < 11) {
        setFields({
          ...fields,
          [field]: phone.match(/\d{1,4}$|\d{1,3}/g).join(""),
        });
      } else if (phone.length < 11) {
        setFields({ ...fields, [field]: phone });
      }
    }
  };  

  const handlerCheck = ({ target }) => {
    const { checked, dataset } = target;
    const { field } = dataset;
    setFields({ ...fields, [field]: checked });
  };

  const isUserAbove21 = (dateOfBirth) => {
    const currentDate = moment();
    const age = currentDate.diff(dateOfBirth, 'years');
    return age >= 21;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsProgress(true);
    await complianceUpdate(fields).then((res)=>{
      console.log("resresres",res)
      history.push(routers.login.path, {});
    })
  };

  return (
    <Container>
      <Row>
        <Col
          xs="12"
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 3, span: 6 }}
          className="p-2 py-5"
        >
          <div className="box p-3 p-md-5">
            <Form onSubmit={onSubmit}>
              <h2 className="title text-center font-weight-bold pb-3 pb-mb-5">
                Age Gate
              </h2>
              <Col
                className="p-1"
              >              
                <span className={style.policiesTitle}>
                  Dear User, we are updating our policies to comply with all applicable laws and regulations. This includes implementing new practices for better user notification and safety. As part of these updates, we kindly request that you update your phone number and date of birth. This information will not only help us improve our service but also ensures we meet legal requirements for user identification and notification. Thank you for your understanding and cooperation.
                </span>
              </Col>
              <Form.Control
                type="text"
                placeholder="Username"
                value={user.username}
                data-field="username"
                readOnly
              />
              <Form.Control
                type="email"
                placeholder="E-mail"
                value={user.email}
                data-field="email"
                readOnly
              />              
              {userIsAbove21 &&
                <span className={style.ageText}>
                  You need to be 21 years or older to sign up
                </span>
              }            
              <DatePicker 
                className={userIsAbove21 ? '' : 'invalid-input'}
                format="MM/DD/YYYY" 
                placeholder="Date of Birth*"
                onChange={handleDateChange}
                clearIcon={<span />}
                required
                inputReadOnly
              />       
              {!userIsAbove21 &&
                <Alert variant="danger">You must be 21 years or older to sign up</Alert>
              }
              <Form.Control
                type="text"
                placeholder="Cell Phone Number*"
                value={fields.phone}
                data-field="phone"
                autoComplete="new-email"
                onChange={handlerFields}
                // pattern="^(1s?)?((([0-9]{3}))|[0-9]{3})[s-]?[\0-9]{3}[s-]?[0-9]{4}$"
                required
              />
              <Form.Check>
                <Form.Check.Input
                  bsPrefix="bns-consent-checkbox"
                  type="checkbox"
                  required
                  checked={fields.consentForTextMessages}
                  onChange={handlerCheck}
                  data-field="consentForTextMessages"
                  id="checkConsentForTextMessages"
                />
                <Form.Check.Label htmlFor="checkConsentForTextMessages">{""}</Form.Check.Label>
                <Form.Check.Label >
                  <span className={style.checkboxText}>
                    By checking this box, you are providing consent to receive marketing text messages using an automated telephone dialing system from The BM Revolution or third-parties acting on its behalf. Consent is not a condition of purchase. Msg&Data rates may apply.
                  </span>
                </Form.Check.Label>
              </Form.Check>
              {/* {errMessage 
              && <Alert variant="danger">{errMessage.message}</Alert>} */}
              <Form.Check>
                <Form.Check.Input
                  bsPrefix="bns-checkbox"
                  type="checkbox"
                  required
                  checked={fields.acceptTermsConditions}
                  onChange={handlerCheck}
                  data-field="acceptTermsConditions"
                  id="check"
                />
                <Form.Check.Label htmlFor="check">{""}</Form.Check.Label>
                <Form.Check.Label>
                  I understand and accept all the
                  <Link to={routers.termsAndConditions.path}>Terms and Conditions</Link>
                  of this site
                </Form.Check.Label>
              </Form.Check>              
              <Button variant="bns" type="submit" className="w-100" disabled={loading || checkForm}>
                {loading && <FontAwesomeIcon icon={faSync} className="mr-2" spin />}
                Confirm
              </Button>          
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

// export default Dob;
export default connect(mapStateToProps, null)(Dob);