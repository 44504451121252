const commentActionTypes = {
  COMMENT_POST_REQUEST: "COMMENT_POST_REQUEST",
  COMMENT_POST_SUCCESS: "COMMENT_POST_SUCCESS",
  COMMENT_POST_FAILED: "COMMENT_POST_FAILED",

  COMMENT_WITH_PRODUCT_REQUEST: "COMMENT_WITH_PRODUCT_REQUEST",
  COMMENT_WITH_PRODUCT_SUCCESS: "COMMENT_WITH_PRODUCT_SUCCESS",
  COMMENT_WITH_PRODUCT_FAILED: "COMMENT_WITH_PRODUCT_FAILED",

  COMMENT_WITH_USER_REQUEST: "COMMENT_WITH_USER_REQUEST",
  COMMENT_WITH_USER_SUCCESS: "COMMENT_WITH_USER_SUCCESS",
  COMMENT_WITH_USER_FAILED: "COMMENT_WITH_USER_FAILED",
}

export default commentActionTypes