/* eslint-disable react-hooks/exhaustive-deps */
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import routers from "router";
import withAuth from "utils/HOC/withAuth";
import {getFreeSeat, getWebinarSeats, isReservedUser, reservedSeats} from "utils/services/api";
import cartIcon from "./../../images/icons/cart.svg";
import screen from "./../../images/purchase_stadium.png";
import { addItem } from "./../../redux/cart/Actions";
import * as style from "./ReservedSeats.module.scss";

function ReservedSeats({ match, history, location }) {
  //console.log("LOCATION STATE",location.state)
  const { id } = match.params;
  const user_id = useSelector((state) => (state.auth.user ? state.auth.user.id : null));

  const [seats, setSeats] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState([]);
  const [reward, setReward] = useState({hasReward: false, freeSeats: 0});
  const [isProgress, setIsProgress] = useState(false);
  const [canRedeem, setCanRedeem] = useState(false);
  const [freeSeats, setFreeSeats] = useState(0);

  const cart = useSelector((state) => state.cart);

  const { name, price_per_seats, category_id, webinar_type } = location.state;

  useEffect(() => {

    validateRedeemButton();

    async function load() {
      const res = await isReservedUser(id, user_id);
      if (res) {
        if (res.message)
          setErrMessage(res.message);
        else {
          const { data } = res;
          if (data.is_reserved) {
            getWebinarSeats(id)
            .then((res) => {
              history.push(routers.buyProduct.path, {
                productType: "webinar",
                seats: data.seatNo,
                price: price_per_seats,
                reward: validateReward(res.message,data.seatNo),
                quantity: data.seatNo.length,
                id,
              });
            })
            .catch((err) => setErrMessage(err.message));
          } else {
            getWebinarSeats(id)
                .then((res) => {
                  setSeats( res.message)
                })
                .catch((err) => setErrMessage(err.message));
          }
        }
      }
    }

    if (!!cart?.find((el) => el.id === id)) {
      history.push(routers.cart.path);
      return;
    }
    load();

  }, []);

  const validateRedeemButton = () => {
    const body = {webinarId: id, userId: user_id};
    getFreeSeat(body)
        .then(response => {
          if (response.data) {
            setFreeSeats(response.data.seatNumber);
            setCanRedeem(true);
          } else {
            setCanRedeem(false);
          }
        });
  }

  const redeemSeat = () => {
    const product = location.state;
    const {name} = product;
    history.push(routers.rewardProgram.path.replace(":id", id), {...product, freeSeats, name});
  }

  const reservedPlace = (rewardUpdated) => {
    setIsProgress(true);
    reservedSeats({
      webinar_id: id,
      user_id,
      seatNoArray: selectedPlace.map((el) => el),
    })
        .then((res) => {
          setIsProgress(false);
          history.push(routers.buyProduct.path, {
            // place: selectedPlace,
            seats: selectedPlace,
            quantity: selectedPlace.length,
            productType: "webinar",
            price: price_per_seats,
            id,
            category_id,
            reward: rewardUpdated
          });
        })
        .catch((err) => {
          setIsProgress(false);
          setErrMessage(err.message);
        });
  };

  const validateReward = (seats,selectedPlace) => {
    const maxSeat = seats.length;
    const minSeat = 1;
    const buyFirstOrLastSeat = [];
    let reward;
    if(webinar_type !== 'gifts') {
      selectedPlace.map(place => {(place === minSeat || place === maxSeat) && buyFirstOrLastSeat.push(place)});
      //console.log("Seats", seats, "SelectedPlace",selectedPlace,"buyFirstOrLastSeat",buyFirstOrLastSeat);
      const freeSeats = getNumberOfSeatsAvailable( seats,selectedPlace, buyFirstOrLastSeat);
      //console.log("freeSeats",freeSeats);
      reward = {hasReward: Boolean(freeSeats > 0), availableSeats: seats, freeSeats, name};
      //console.log("Reward",reward);

    } else {
      reward = {hasReward: false, freeSeats: 0};
    }
    return reward;
  }

  const getNumberOfSeatsAvailable = (seats,selectedPlace, buyFirstOrLastSeat) => {
    const availableSeats = seats.length - selectedPlace.length;
    if (availableSeats === 0) {
      console.log("availableSeats NO HAY ",availableSeats);
      return 0;
    }

    if (availableSeats < buyFirstOrLastSeat.length) {
      console.log("availableSeats MENOR ",availableSeats);
      return 1;
    }

    if (availableSeats >= buyFirstOrLastSeat.length) {
      console.log("availableSeats MAYOR ",availableSeats, buyFirstOrLastSeat.length);
      return buyFirstOrLastSeat.length;
    }
  }

  const selectPlace = ({ target }) => {
    const { num } = target.dataset;

    const place = Number(num);

    if (selectedPlace.includes(place)) {
      setSelectedPlace(selectedPlace.filter((el) => el !== place));
    } else {
      setSelectedPlace([...selectedPlace, place]);
    }
  };

  const dispatch = useDispatch();

 

  const addCart = () => {
    setIsProgress(true);
    reservedSeats({
      webinar_id: id,
      user_id,
      seatNoArray: selectedPlace.map((el) => el),
    })
        .then((res) => {
          setIsProgress(false);
          dispatch(
              addItem({
                name,
                category_id,
                price: price_per_seats,
                seats: selectedPlace,
                quantity: selectedPlace.length,
                id,
                productType: "webinar",
                webinar_type: webinar_type,
                webinarSeats: seats
              })
          );
          history.push(routers.cart.path);
        })
        .catch((err) => {
          setIsProgress(false);
          setErrMessage(err.message);
        });
  };

  const setRandomSeat = () => {
    const clearSeat = [];

    for (let i = 0; i < seats.length; i++) {
      const element = seats[i];
      if (element === "available" && !selectedPlace.includes(i + 1)) {
        clearSeat.push(i + 1);
      }
    }
    const min = 0;
    const max = clearSeat.length;
    const random = Math.floor(Math.random() * (+max - +min)) + +min;
    if (clearSeat.length) {
      setSelectedPlace([...selectedPlace, clearSeat[random]]);
    }
  };

  const disabledRandom = useMemo(() => {
    const clearSeat = [];
    if (seats) {
      for (let i = 0; i < seats.length; i++) {
        const element = seats[i];
        if (element === "available" && !selectedPlace.includes(i + 1)) {
          clearSeat.push(i + 1);
        }
      }
      return !clearSeat.length;
    }
    return true;
  }, [selectPlace, seats]);

  return (
      <Container>
        <Row>
          <Col>
            <h1 className="text-center font-weight-bold mb-4">Purchase Seats</h1>

            {errMessage && <Alert variant="danger"> {errMessage} </Alert>}
            <div className={style.seats_box}>
              {!seats && <FontAwesomeIcon icon={faSync} spin size="4x" className="mt-5" />}
              {seats &&
              seats.map((place, i) => (
                  <button
                      type="button"
                      key={Math.random()}
                      disabled={place !== "available"}
                      data-num={i + 1}
                      onClick={selectPlace}
                      className={`${style.place_btn} ${style[place]} ${selectedPlace.includes(i + 1) ? style.selected : ""}`}
                  >
                    {i + 1}
                  </button>
              ))}
            </div>

            {seats && (
                <>
                  <div className={style.img__wrp}>
                    <img src={screen} alt="screen" className={style.img} />
                  </div>

                  <div className={style.type_box}>
                    <p className={style.type}>
                      <span className={style.available}></span> Available
                    </p>
                    <p className={style.type}>
                      <span className={style.reserved}></span> Reserved
                    </p>
                    <p className={style.type}>
                      <span className={style.taken}></span> Taken
                    </p>
                  </div>

                  <div className={style.btnRow}>
                    <Button
                        variant="bns"
                        className={clsx("px-5 m-0", style.bntNext)}
                        onClick={(e)=>{
                          //console.log('!!!!SelectedPlace',selectedPlace,"!!!!seats",seats);
                          let reward = validateReward(seats,selectedPlace);
                          //console.log('!!!!reward',reward);
                          setReward(reward);
                          reservedPlace(reward);
                        }}
                        disabled={isProgress || selectedPlace.length === 0}
                    >
                      {isProgress && <FontAwesomeIcon icon={faSync} className="mr-2" spin />}
                      proceed to checkout
                    </Button>
                    <button
                        variant="bns"
                        type="button"
                        className={style.btnAddToCart}
                        onClick={addCart}
                        disabled={isProgress || selectedPlace.length === 0}
                    >
                      <img src={cartIcon} alt="cart icon" />
                      add to cart
                    </button>
                    <Button
                        variant="bns"
                        className={clsx("px-5 m-0", style.bntRandom)}
                        onClick={setRandomSeat}
                        disabled={disabledRandom}
                    >
                      Random Seat
                    </Button>
                  </div>
                  {canRedeem && <div className={style.btnFreeSeat}>
                    <Button
                        variant="bns"
                        className={clsx("px-5 m-0", style.bntRandom)}
                        onClick={redeemSeat}
                    >
                      Redeem reward
                    </Button>
                  </div>}
                </>
            )}
          </Col>
        </Row>
      </Container>
  );
}

export default withAuth(ReservedSeats);
