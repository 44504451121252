import React from "react";
import LogoImage from "@images/logo.png";
function Logo() {
  return (
    <div>
      <img src={LogoImage} />
    </div>
  );
}

export default Logo;
