/* eslint-disable react-hooks/exhaustive-deps */
import {faCheck, faExclamation, faSync, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import routers from "router";
import withAuth from "utils/HOC/withAuth";
import {getFreeSeat, getWebinarSeats, isReservedUser, redeemReward, removeFreeSeat} from "utils/services/api";
import screen from "./../../images/purchase_stadium.png";
import "./RewardProgram.scss";
import * as style from "@pages/BuyProduct/index.module.scss";
import {addItem} from "@redux/bookends/Actions";

function RewardProgram({match, history, location}) {
    const {name: getName, price_per_seats, category_id} = location.state;
    const {freeSeats: freeSeatsShow} = location.state;
    const {id: getId} = match.params;

    const user_id = useSelector((state) => (state.auth.user ? state.auth.user.id : null));
    const {bookends: getBookends} = useSelector((state) => state);
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const [seats, setSeats] = useState(null);
    const [errorMessage, setErrorMessage] = useState({label: '', show: false});
    const [errMessage, setErrMessage] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState([]);
    const [isOpen, setIsOpen] = useState({open: false});
    const [errorModal, setErrorModal] = useState({open: false});
    const [multipleBookendsIsOpen, setMultipleBookendsIsOpen] = useState({open: false});
    const [multipleBookends, setMultipleBookends] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageSeats, setErrorMessageSeats] = useState('');
    const [showErrorMessageSeats, setShowErrorMessageSeats] = useState(false);
    const [errorMessageSeatsAvailable, setErrorMessageSeatsAvailable] = useState('');
    const [showErrorMessageSeatsAvailable, setShowErrorMessageSeatsAvailable] = useState(false);
    const [nameShow, setNameShow] = useState(getName);
    const [freeSeats, setFreeSeats] = useState(freeSeatsShow);
    const [bookends, setBookends] = useState(getBookends);
    const [id, setId] = useState(getId);

    useEffect(() => {
        if (bookends?.freeSeatsList) {
            purchaseWithCart();
        } else {
            purchaseWithCheckout();
        }
    }, [multipleBookendsIsOpen]);

    const purchaseWithCart = async () => {
        const {name, id: idAttempt, freeSeats} = bookends.freeSeatsList[0];
        setId(idAttempt)
        setNameShow(name);
        setFreeSeats(freeSeats);
        const response = await isReservedUser(id, user_id);
        if (response) {
            if (response.message)
                setErrMessage(response.message);
            else {
                console.log(id)
                getWebinarSeats(id)
                    .then((res) => {
                        const availableSeats = res.message
                            .filter((seatActual, index) => seatActual === 'available' && (index !== 0) && (index + 1 !== res.message.length));
                        if (availableSeats.length) {
                            setSeats(res.message)
                        } else {
                            setErrorMessageSeatsAvailable('There are no seats available to redeem the prize')
                            setShowErrorMessageSeatsAvailable(true);
                        }
                    })
                    .catch((err) => setErrMessage(err.message));

            }
        }
    }

    const purchaseWithCheckout = () => {
        async function load() {
            const res = await isReservedUser(id, user_id);
            if (res) {
                if (res.message)
                    setErrMessage(res.message);
                else {
                    getWebinarSeats(id)
                        .then((res) => {
                            const availableSeats = res.message
                                .filter((seatActual, index) => seatActual === 'available' && (index !== 0) && (index + 1 !== res.message.length));
                            if (availableSeats.length) {
                                setSeats(res.message)
                            } else {
                                setErrorMessageSeatsAvailable('There are no seats available to redeem the prize')
                                setShowErrorMessageSeatsAvailable(true);
                            }
                        })
                        .catch((err) => setErrMessage(err.message));

                }
            }
        }

        if (!!cart?.find((el) => el.id === id)) {
            history.push(routers.cart.path);
            return;
        }
        load();
    }

    const selectPlace = ({target}) => {
        const {num} = target.dataset;

        const place = Number(num);

        if (selectedPlace.includes(place)) {
            setSelectedPlace(selectedPlace.filter((el) => el !== place));
        } else {
            setSelectedPlace([...selectedPlace, place]);
        }
    };

    const redeemRewardAction = async () => {
        setIsLoading(true);
        if (selectedPlace.length > freeSeats) {
            setErrorMessage({
                label: `You can select a maximum of ${freeSeats} seat(s)!`,
                show: true
            })
            setIsLoading(false);
        } else {
            setErrorMessage({label: '', show: false})
            const body = {webinarId: id, userId: user_id};
            await getFreeSeat(body)
                .then(response => {
                    if (response.data) {
                        doRedeem();
                    } else {
                        setErrorModal({open: true});
                        setIsLoading(false);
                    }
                })
        }
    }

    const doRedeem = () => {
        redeemReward({
            seatNoArray: selectedPlace.map((el) => el),
            webinar_id: id,
            user_id,
        })
            .then(async (response) => {
                setIsLoading(false);
                if (response.status === 'error') {
                    setShowErrorMessageSeats(true);
                    setErrorMessageSeats(response.data);
                } else {
                    await removeFreeSeat({
                        seatNoArray: selectedPlace.map((el) => el),
                        webinar_id: id,
                        user_id,
                    });
                    if (bookends?.freeSeatsList?.length > 1) {
                        const freeSeatsList = bookends.freeSeatsList.slice(1, bookends.freeSeatsList.length);
                        setMultipleBookends(freeSeatsList);
                        setMultipleBookendsIsOpen({open: true})
                    } else {
                        setIsOpen({open: true})
                    }
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log('ERROR::: ', err);
            })
    }

    const closeModal = () => {
        dispatch(addItem([]));
        window.location.href = routers.products.path;
    };

    const closeErrorModal = () => {
        setShowErrorMessageSeats(false);
        setErrorMessageSeats('')
        window.location.reload();
    };

    const closeMultipleModal = () => {
        const freeSeatsList = bookends.freeSeatsList.slice(1, bookends.freeSeatsList.length);
        const {name, id: idAttempt, freeSeats} = freeSeatsList[0];
        dispatch(addItem({freeSeatsList}));
        setId(idAttempt)
        setMultipleBookendsIsOpen({open: false})
        setBookends({freeSeatsList})
        setSeats(null)
        setSelectedPlace([])
        history.push(routers.rewardProgram.path.replace(":id", idAttempt), {...{}, freeSeats, name});
    };

    return (
        <>
            <Modal
                show={showErrorMessageSeatsAvailable}
                onHide={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-bns"
            >
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faExclamation} className={style.icon} onClick={closeErrorModal}/>
                </Modal.Header>
                <Modal.Body>
                    <h2>{errorMessageSeatsAvailable}</h2>
                </Modal.Body>
            </Modal>

            <Modal
                show={showErrorMessageSeats}
                onHide={closeErrorModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-bns"
            >
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faExclamation} className={style.icon} onClick={closeErrorModal}/>
                </Modal.Header>
                <Modal.Body>
                    <h2>{errorMessageSeats}</h2>
                </Modal.Body>
            </Modal>

            <Modal
                show={errorModal.open}
                onHide={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-bns"
            >
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faCheck} className={style.icon} onClick={closeModal}/>
                </Modal.Header>
                <Modal.Body>
                    <h2>{'The prize is no longer available!'}</h2>
                </Modal.Body>
            </Modal>

            <Modal
                show={isOpen.open}
                onHide={closeModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-bns"
            >
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faCheck} className={style.icon} onClick={closeModal}/>
                </Modal.Header>
                <Modal.Body>
                    <h2>{'Successfully!'}</h2>
                </Modal.Body>
            </Modal>

            <Modal
                show={multipleBookendsIsOpen.open}
                onHide={closeMultipleModal}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-bns modal-multiple-bookend"
            >
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faCheck} className={style.icon} onClick={closeMultipleModal}/>
                </Modal.Header>
                <Modal.Body>
                    <h2>{`You still have the following seats to redeem`}</h2>
                    <table className="table-multiple-bookend">
                        <thead>
                        <tr>
                            <td>Webinar name</td>
                            <td>Number of seats won</td>
                        </tr>
                        </thead>
                        <tbody>
                        {multipleBookends.map((bookend, index) => (
                            <tr key={`bookendsList-${index}`}>
                                <td>{bookend.name}</td>
                                <td>{bookend.freeSeats}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>

            <Container>
                <Row>
                    <Col>
                        <h1 className="text-center font-weight-bold mb-4">Select your prize seat for {nameShow}</h1>
                        {errMessage && <Alert variant="danger"> {errMessage} </Alert>}
                        {errorMessage.show && <Alert variant="danger"> {errorMessage.label} </Alert>}
                        <div className={'seats_box'}>
                            {!seats && <FontAwesomeIcon icon={faSync} spin size="4x" className="mt-5"/>}
                            {seats &&
                                seats.map((place, i) => (
                                    <button
                                        type="button"
                                        key={Math.random()}
                                        disabled={place !== "available" || (i === 0) || (i + 1 === seats.length)}
                                        data-num={i + 1}
                                        onClick={selectPlace}
                                        className={`place_btn ${place} ${selectedPlace.includes(i + 1) ? 'selected' : ""}`}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                        </div>

                        {seats && (
                            <>
                                <div className={'img__wrp'}>
                                    <img src={screen} alt="screen" className={'img'}/>
                                </div>

                                <div className={'type_box'}>
                                    <p className={'type'}>
                                        <span className={'available'}></span> Available
                                    </p>
                                    <p className={'type'}>
                                        <span className={'reserved'}></span> Reserved
                                    </p>
                                    <p className={'type'}>
                                        <span className={'taken'}></span> Taken
                                    </p>
                                </div>

                                <div className='btnRow-reward'>
                                    <Button
                                        disabled={selectedPlace.length === 0 || isLoading}
                                        variant="bns"
                                        className={clsx("px-5 m-0")}
                                        onClick={redeemRewardAction}
                                    >
                                        Redeem reward
                                    </Button>
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default withAuth(RewardProgram);
