import * as types from "./Types";

const bookendsReducer = (state = [], { type, payload }) => {
    switch (type) {
        case types.ADD_ITEM:
            payload.key = Math.random();
            return payload;
        case types.REMOVE_ITEM:
            return state.filter((item) => item.id !== payload.id);
        case types.CHANGE_ITEM:
            const { key, quantity } = payload;
            return state.map((item) => (item.key === key ? { ...item, quantity } : item));
        case types.CLEAR_BOOKENDS:
            return [];
        default:
            return state;
    }
};

export default bookendsReducer;
