const purchaseActionTypes = {
  PURCHASE_HISTORY_REQUEST: "PURCHASE_HISTORY_REQUEST",
  PURCHASE_HISTORY_SUCCESS: "PURCHASE_HISTORY_SUCCESS",
  PURCHASE_HISTORY_FAILED: "PURCHASE_HISTORY_FAILED",

  WEBINAR_WINNER_HISTORY_REQUEST: "WEBINAR_WINNER_HISTORY_REQUEST",
  WEBINAR_WINNER_HISTORY_SUCCESS: "WEBINAR_WINNER_HISTORY_SUCCESS",
  WEBINAR_WINNER_HISTORY_FAILED: "WEBINAR_WINNER_HISTORY_FAILED",
}

export default purchaseActionTypes